import { SearchHitsMetadata } from "@opensearch-project/opensearch/api/types";

export interface OsSearchRequestTransformer<T> {
    transform(response: SearchHitsMetadata): T
}

export interface LatLngCoords {
    lat?: number;
    lon?: number;
}

interface Query {
    query: Record<string, any>;
}

 export const getMarkersQueryBuilder = (nwCoords: LatLngCoords, seCoords: LatLngCoords, size: number, excludeMarkers?: string[]) => {
    let body: any =  {
        "size": size ?? 50,
        "query": {
            "bool": {
                "filter": [
                    {
                        "geo_bounding_box": {
                            "location": {
                                "top_left": {
                                    ...nwCoords
                                },
                                "bottom_right": {
                                    ...seCoords
                                }
                            }
                        }
                    }
                ]
            }
        },
        "sort": [{
            "timestamp": {
                "order": "asc"
            }
        }]
    }

    if (excludeMarkers) {
        body.query.bool.must_not = {
            "terms" : {
                "post_id": excludeMarkers
            }
        }
    }

    return body

 }