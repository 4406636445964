import React, { useState, useEffect, useContext } from 'react'
import { Pkce, generatePkceVerifierAndChallenge, getTokenFromAuthCode } from '../aws/cognito';
import { useNavigate } from 'react-router-dom'

const PKCE_CODE_VERIFIER = 'code_verifier';
const ACCESS_TOKEN = 'access_token'
const REFRESH_TOKEN = 'refresh_token'

export enum AuthStatus {
    SignedIn,
    SignedOut
}

interface SessionInfo {
    accessToken?: string;
    refreshToken?: string;
    idToken?: string;
}

export interface IAuth {
    sessionInfo?: SessionInfo
    authStatus?: AuthStatus
    getEmail?: () => string | null
    userSignIn?: () => Promise<any>
    userSignOut?: () => Promise<any>
    completeSignIn?: () => Promise<any>
}

const defaultState: IAuth = {
    sessionInfo: {},
    authStatus: AuthStatus.SignedOut,
}

type Props = {
    children?: React.ReactNode
}

export const AuthContext = React.createContext(defaultState)

export const AuthIsSignedIn = ({ children }: Props) => {
    const { authStatus }: IAuth = useContext(AuthContext)

    return <>{authStatus === AuthStatus.SignedIn ? children : null}</>
}

export const AuthIsNotSignedIn = ({ children }: Props) => {
    const { authStatus }: IAuth = useContext(AuthContext)

    return <>{authStatus === AuthStatus.SignedOut ? children : null}</>
}

const AuthProvider = ({ children }: Props) => {
    const [authStatus, setAuthStatus] = useState(AuthStatus.SignedOut)
    const [sessionInfo, setSessionInfo] = useState<SessionInfo>({})
    const [attrInfo, setAttrInfo] = useState([])

    const navigate = useNavigate()

    // TODO session info needs to persist after refresh
    useEffect(() => {
        async function getSessionInfo() {
            try {
                if (Object.keys(sessionInfo).length !== 0) {
                    // setting these because they will persist if page is refreshed
                    sessionStorage.setItem(ACCESS_TOKEN, `${sessionInfo.accessToken}`)
                    sessionStorage.setItem(REFRESH_TOKEN, `${sessionInfo.refreshToken}`)
                }
            } catch (err) {
                console.log('sign in failed')
            }

            sessionStorage.removeItem(PKCE_CODE_VERIFIER)
            navigate('/')
        }
        getSessionInfo()
    }, [authStatus])

    const parseJwt = (token: string) => {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    }

    const getEmail = (): string | null => {
        if (Object.keys(sessionInfo).length !== 0) {
            const parsed = parseJwt(sessionInfo.idToken!)
            return parsed.email
        }
        return null;
    }

    // lol
    const getRedirectUrl = (): string => {
        if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "") {
            return "http://localhost:3000";
        } else {
            return "https://narthy.com";
        }
    }

    const userSignOut = async () => {
        window.location.href = `https://auth.narthy.com/logout?client_id=${process.env.REACT_APP_COGNITO_APP_ID!}&logout_uri=${getRedirectUrl()}`;
    }

    // redirects to cognito hosted sign in page
    const userSignIn = async () => {
        const pkce: Pkce = await generatePkceVerifierAndChallenge();
        // persists afer redirect
        sessionStorage.setItem(PKCE_CODE_VERIFIER, pkce.verifier)
        window.location.href = `https://auth.narthy.com/login/?response_type=code&client_id=${process.env.REACT_APP_COGNITO_APP_ID!}&redirect_uri=${getRedirectUrl()}&code_challenge=${pkce.codeChallenge}&code_challenge_method=S256`;
    }



    const exchangeCodeForTokens = async (): Promise<boolean> => {
        const params = new URLSearchParams(window.location.search)
        const code = params.get('code')
        const codeVerifier = sessionStorage.getItem(PKCE_CODE_VERIFIER)
        if (!code || !codeVerifier) {
            console.log('missing code or verifier')
            return false

        } else {
            const tokens = await getTokenFromAuthCode(code, codeVerifier, getRedirectUrl());
            if (tokens !== null) {
                setSessionInfo(tokens)
                const idToken = parseJwt(tokens.idToken) 
                console.log(idToken)
                return true
            }
        }
        return false
    }

    const completeSignIn = async () => {
        const tokenExchangeSuccess = await exchangeCodeForTokens();
        if (tokenExchangeSuccess) {
            setAuthStatus(AuthStatus.SignedIn)
        } else {
            setAuthStatus(AuthStatus.SignedOut)
        }
    }

    const state: IAuth = {
        authStatus,
        sessionInfo,
        getEmail,
        userSignIn,
        userSignOut,
        completeSignIn,
    }

    return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

export default AuthProvider
