
export const placeMarker = (
    map: google.maps.Map,
    infoWindow: google.maps.InfoWindow,
    title: string,
    coords: google.maps.LatLng | null,
    message: string,
    pinElement: HTMLElement
): boolean => {
    if (!coords) {
        return false;
    }

    const marker = new google.maps.marker.AdvancedMarkerElement({
        map,
        position: coords,
        title: title,
        content: pinElement
    })

    marker.addListener('click', () => {
        infoWindow.close();
        infoWindow.setContent(message ? message : "fallback");
        infoWindow.open(marker.map, marker);
    });

    return true;
}