import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavbarToggle from "react-bootstrap/NavbarToggle";
import { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { AuthContext } from "../contexts/authContext";
import { Container, Offcanvas } from "react-bootstrap";

export const TopNavBar: React.FunctionComponent<{}> = () => {
  const [clickedSignIn, setClickedSignIn] = useState(false);
  const [clickedSignOut, setClickedSignOut] = useState(false);

  const auth = useContext(AuthContext);

  useEffect(() => {
    const handleClick = async () => {
      if (clickedSignIn) {
        await auth.userSignIn!();
      } else if (clickedSignOut) {
        await auth.userSignOut!();
      }
    };

    handleClick();
  }, [clickedSignIn, clickedSignOut]);

  const getNavText = () => {
    const email = auth.getEmail!();
    if (email !== null) {
      return `Logout`;
    }
    return "Login";
  };

  const onCLick = () => {
    if (!auth.getEmail!()) {
      setClickedSignIn(true);
    } else {
      setClickedSignOut(true)
    }
  };

  return (
    <>
      <Navbar
        sticky="top"
        bg="dark"
        variant="dark"
        data-bs-theme="dark"
        expand="false"
      >
        <Container fluid>
          <NavbarToggle
            aria-controls="offcanvasDarkNavbar"
            className="me-auto"
          />

          <Navbar.Offcanvas
            data-bs-theme="dark"
            id="offcanvasDark"
            aria-labelledby="offcanvasDarkNavbarLabel"
            placement="start"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasDarkLabel">Stuff</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link href="#action1">Explore</Nav.Link>
                <Nav.Link href="#action1">Groups</Nav.Link>
                <Nav.Link href="#action1">Settings</Nav.Link>
                <Nav.Link href="#action1">Advertise</Nav.Link>
                <Nav.Link href="#action1">About</Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
          <Navbar.Brand>N A R T H Y</Navbar.Brand>

          <Nav className="ms-auto">
            <Nav.Link onClick={onCLick}>{getNavText()}</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Outlet />
    </>
  );
};
