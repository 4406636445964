import axios, { AxiosRequestConfig } from "axios"
import { SearchHitsMetadata } from '@opensearch-project/opensearch/api/types';
import { postRequestModel } from "../model/aws";

const endpoint = "https://mi8960va5j.execute-api.us-west-2.amazonaws.com/beta/marker";

// need to pass query
export async function getMarkers<T>(query: Record<string, any>) : Promise<SearchHitsMetadata<T> | undefined> {
    try {
        const res = await axios.post(`${endpoint}/search`,  { body: JSON.stringify(query) })
        console.log(res)
        return JSON.parse(res.data.body) as SearchHitsMetadata<T>
    } catch(e) {
        console.error(`Exception GET markers: ${e}`)
        return
    }
}

export const postMarker = async (requestBody: postRequestModel): Promise<boolean> => {
    try {
        await axios.post(endpoint, { body: JSON.stringify(requestBody) })
        return true;
    } catch(e) {
        console.error(`Exception POST marker: ${e}`)
    }   return false;
}