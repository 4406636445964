import { Libraries, Loader } from "@googlemaps/js-api-loader";

// this needs to run before the google map can be loaded
export default async function initMap(): Promise<boolean> {
    try {
        console.log("init map")
        const mapsLibraries: Libraries = ["core", "marker", "maps"]
        const loader = new Loader({
            apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY!,
            version: "weekly",
            libraries: mapsLibraries
        });

        await loader.importLibrary("maps")
        await loader.importLibrary("core")
        await loader.importLibrary("marker")

        return true
    } catch (e) {
        console.error(`Error - failed to load google map libraries\n${e}`)
        return false;
    }
}
