import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import { mapClickModalProps } from "../model/map";
import { useEffect, useState } from "react";
import { placeMarker } from "../google/markers";
import { postRequestModel } from "../model/aws";
import { postMarker } from "../aws/apigw";
import { PinType, createGeneralPinElement, createHazardPinElement, getPinStyle } from "../google/pinStyles";
import DropdownButton from "react-bootstrap/DropdownButton";

const MAX_CHARS = 64; // arbitrary

// for the dropdown
const LABEL = "Label";

export const MapClickModal: React.FC<mapClickModalProps> = ({
  show,
  map,
  infoWindow,
  getCoordinates,
  onModalHide,
}) => {
  const [makePostRequest, setMakePostRequest] = useState<boolean>(false);
  const [modalSubmissionMessage, setModalSubmissionMessage] =
    useState<string>("");
  const [pinType, setPinType] = useState<PinType | null>(null);
  const [dropdownText, setDropdownText] = useState<PinType | String>(LABEL);

  const onHide = () => {
    setModalSubmissionMessage("");
    onModalHide();
    setPinType(null);
  };

  const onModalTextBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModalSubmissionMessage(e.target.value);
  };

  const onModalClickSubmit = (
    e: React.MouseEvent<HTMLButtonElement> | undefined
  ) => {
    if (modalSubmissionMessage !== "") {
      setMakePostRequest(true);
      const pinStyleForNewPin = pinType ?? "General"
      placeMarker(
        map!,
        infoWindow,
        Date.now().toString().slice(-5),
        getCoordinates(),
        modalSubmissionMessage,
        getPinStyle(pinStyleForNewPin)!
      );
    }
  };

  const numCharsRemaining = () => {
    const diff = MAX_CHARS - modalSubmissionMessage.length;
    if (diff <= 0) {
      return 0;
    }
    return diff;
  };

  const modalMessage = () => {
    return `${numCharsRemaining()} characters remaining`;
  };

  // user clicks "post", send message directly to apigw. eventually, to kinesis
  useEffect(() => {
    if (makePostRequest) {
      const lat = getCoordinates()!.lat();
      const lng = getCoordinates()!.lng();
      if (!lat || !lng) {
        console.log("lat or lng is undefined, not placing marker");
        return;
      }

      const postRequestBody: postRequestModel = {
        lat,
        lng,
        hoverText: Date.now().toString().slice(-5),
        message: modalSubmissionMessage,
        pinStyle: pinType ?? "General",
        userId: `user-11111222222`, // change to oauth sub
        postId: `post-${Date.now()}`,
        timestamp: Date.now(),
      };

      postMarker(postRequestBody)
        .then(() => {
          console.log("Post Success");
        })
        .catch((e) => {
          console.error(`caught exception in post marker" ${e}`);
        })
        .finally(() => {
          setMakePostRequest(false);
        });
      onHide();
    }
  }, [makePostRequest]);

  // use clicks a dropdown item
  useEffect(() => {
    if (pinType !== null) {
      setDropdownText(pinType);
    } else {
      setDropdownText(LABEL);
    }
  }, [pinType]);

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>What's there?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="mapModal.control">
              <Form.Label>{modalMessage()}</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                // value={userText}
                onChange={onModalTextBoxChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Dropdown>
            <DropdownButton
              variant="success"
              id="dropdown-item-button"
              title={dropdownText}
            >
              <Dropdown.Item as="button" onClick={() => setPinType("Hazard")}>
                Hazard
              </Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => setPinType("Event")}>
                Event
              </Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => setPinType("Meet")}>
                Meet
              </Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => setPinType("General")}>
                General
              </Dropdown.Item>
            </DropdownButton>
          </Dropdown>
          <Button variant="primary" onClick={onModalClickSubmit}>
            Public post
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
