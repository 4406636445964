import { GoogleMap } from "@react-google-maps/api"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { MapClickModal } from "../components/MapClickModal";
import { createGeneralPinElement, createHazardPinElement, getPinStyle } from "../google/pinStyles";
import { getMarkers } from "../aws/apigw";
import { NormalizedMarker } from "../model/aws";
import { placeMarker } from "../google/markers";
import { AuthContext } from "../contexts/authContext";
import { getMarkersQueryBuilder } from "../aws/queryutils";

export default function Home() {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [coordinates, setCoordinates] = useState<google.maps.LatLng | null>(null);
    const [viewportChanged, setViewportChanged] = useState<boolean>(true); // true to get markers to appear on initial page load/refresh
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const onMapLoad = useCallback((map: google.maps.Map) => {
        setMap(map)
    }, [])

    const auth = useContext(AuthContext)

    // quick/dirty way to prevent getting markers that we've already retrieved from opensearch
    // the Terms query has a length limit so need to refactor this later
    const [retrievedMarkers, setRetrievedMarkers] = useState<string[]>([]);

    useEffect(() => {
        const checkForAuthCode = async () => {
            console.log('checking for auth code')
            const params = new URLSearchParams(window.location.search)
            const code = params.get('code')
            if (code !== null) {
                await auth.completeSignIn!()
            }
        }
        checkForAuthCode()
    }, [])



    // used to control when to get markers when the viewport changes
    var onIdlePlusTimeout: NodeJS.Timeout | string | number | undefined = undefined;
    // Create an info window to share between markers.
    const infoWindow = new google.maps.InfoWindow();
    // portland
    const center = useMemo(() => ({ lat: 45.5152, lng: -122.6784 }), []);

    useEffect(() => {
        const retrieveAndDisplayMarkers = async () => {
            try {
                const bounds = map?.getBounds()
                const northEast = bounds?.getNorthEast()
                const southWest = bounds?.getSouthWest()
                const northWest = {
                    lat: northEast?.lat(),
                    lon: southWest?.lng()
                }
                const southEast = {
                    lat: southWest?.lat(),
                    lon: northEast?.lng()
                }
                if (northEast === undefined || southWest === undefined) {
                    return;
                }
                console.log(`"NorthEast: ${northEast}, southWest: ${southWest}`)
                const query = getMarkersQueryBuilder(northWest, southEast, 50, retrievedMarkers.length > 1 ? retrievedMarkers : undefined)
                const response = await getMarkers<NormalizedMarker>(query)
                if (!response || response.total === 0) {
                    return
                }
    
                const markers = [...retrievedMarkers]
                response.hits.forEach((marker) => {
                    const ltLng = new google.maps.LatLng({ lat: marker._source!.location.lat, lng: marker._source!.location.lon })
                    // need to customize
                    const pinStyle = getPinStyle(marker._source!.pin_style)
                    const success = placeMarker(map!, infoWindow, marker._source!.hover_text, ltLng, marker._source!.message, pinStyle ?? createGeneralPinElement())
                    if (marker._source!.post_id) {
                        markers.push(marker._source!.post_id)
                    }
                })
                setRetrievedMarkers(markers)
                console.log(`Retrieved markers: ${markers}`)
            } catch (e) {
                console.log(`exception getting markers ${e}`)
            } finally {
                setViewportChanged(false);
            }   
        }
        
        if (viewportChanged === true) {
            retrieveAndDisplayMarkers()
        }

    }, [viewportChanged])

    // Reference for options:
    // https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
    const options = {
        mapId: "42f0be14528cdbca",
        streetViewControl: false,
        // hides businesses and public transit icons.. or should
        disableDefaultUI: true,

        styles: [{
            featureType: "poi.business",
            stylers: [{ visibility: "off" }],
        },
        {
            featureType: "transit",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
        }],
        clickableIcons: false,
        minZoom: 5,
        maxZoom: 18
    }

    // zoom 13 is where we start aggregating

    const onMapClick = (e: google.maps.MapMouseEvent): void => {
        setShowModal(true);
        setCoordinates(e.latLng);
    }

    const getCoordinatesCallback = (): google.maps.LatLng | null => {
        return coordinates;
    }

    const onModalHide = (): void => {
        setShowModal(false);
    }

    // i'm sure there's a better way to do this
    const getMarkersOnViewportChange = () => {
        setViewportChanged(true);
    }

    const onIdle = (): void => {
        clearTimeout(onIdlePlusTimeout)
        // will get markers in 2 seconds after the viewport idles
        onIdlePlusTimeout = setTimeout((getMarkersOnViewportChange), 2000)
    }

    const onZoomChanged = (): void => {
        console.log(map?.getZoom());
    }

    return (
        <div className="home">
            <GoogleMap
                mapContainerClassName="map-container"
                center={center}
                zoom={12}
                options={options}
                onClick={onMapClick}
                onLoad={onMapLoad}
                onIdle={onIdle}
                onZoomChanged={onZoomChanged}
            >
            </GoogleMap>
            <MapClickModal
                show={showModal}
                map={map}
                infoWindow={infoWindow}
                getCoordinates={getCoordinatesCallback}
                onModalHide={onModalHide}
            />
        </div>
    )
}