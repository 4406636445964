
import { useContext } from "react";
import { AuthContext, AuthStatus } from "../contexts/authContext";

export default function About() {

    const auth = useContext(AuthContext);

    if (auth.authStatus === AuthStatus.SignedIn) {
        return (
            <div>
                <h2>
                    You are signed in
                </h2>
            </div>
        )
    } else {
        return (
            <div>
                <h2>
                    You are not signed in
                </h2>
            </div>
        )
    }
}