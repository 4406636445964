import axios from "axios"

export interface AuthTokens {
    idToken: string;
    accessToken: string;
    refreshToken: string;
}

export const getTokenFromAuthCode = async (authorizationCode: string, codeVerifier: string, redirectUri: string): Promise<AuthTokens | null> => {
    const endpoint = 'https://auth.narthy.com/oauth2/token'
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "no-cache",
    }
    const body = {
        grant_type: "authorization_code",
        client_id: process.env.REACT_APP_COGNITO_APP_ID!,
        scope: "email openid",
        redirect_uri: redirectUri,
        code: authorizationCode,
        code_verifier: codeVerifier
    }

    try {
        const res = await axios.post(endpoint, body, { headers })
        return {
            idToken: res.data.id_token,
            accessToken: res.data.access_token,
            refreshToken: res.data.refresh_token
        }
    } catch (e) {
        console.error(`Exception post Token:\n${e}`)        
        return null;
    }
}

export interface Pkce {
    verifier: string;
    codeChallenge: string;
}

// pkce utilities
const sha256Hash = async (str: string): Promise<ArrayBuffer> => {
    const data = new TextEncoder().encode(str)
    const hash = await crypto.subtle.digest("SHA-256", data);
    return hash;
}

const base64_arraybuffer = async (data: ArrayBuffer): Promise<string> => {
    // Use a FileReader to generate a base64 data URI
    const base64url: string = await new Promise((r) => {
        const reader = new FileReader()
        reader.onload = () => r(reader.result as string)
        reader.readAsDataURL(new Blob([data]))
    })
    return base64url.split(",", 2)[1]
}

export const generatePkceVerifierAndChallenge = async (): Promise<Pkce> => {
    const verifier = window.crypto.randomUUID()
    const hash = await sha256Hash(verifier)
    const b64 = await base64_arraybuffer(hash)
    const codeChallenge = b64
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=/g, "")
    return {
        verifier,
        codeChallenge
    }
}