import { useEffect, useState } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import NoMatch from "./pages/NoMatch";
import initMap from "./google/loadmap";
import { TopNavBar } from "./components/TopNav";
import AuthProvider, {
  AuthIsNotSignedIn,
  AuthIsSignedIn,
} from "./contexts/authContext";
// necessary for react-bootstrap components
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

export default function App() {
  const [googleLibrariesIsLoading, setGoogleLibrariesIsLoading] =
    useState<boolean>(false);
  const [googleLibrariesLoaded, setGoogleLibrariesLoaded] =
    useState<boolean>(false);

  useEffect(() => {
    if (googleLibrariesIsLoading) {
      return;
    }
    setGoogleLibrariesIsLoading(true);
    initMap().then((res) => {
      setGoogleLibrariesLoaded(res);
    });
  }, []);

  // wait for google libraries to load before anything else
  if (!googleLibrariesLoaded) {
    return <div>Loading....</div>;
  }

  return (
    <div className="App">
      <AuthProvider>
        <AuthIsSignedIn>
          <Routes>
            <Route path="/" element={<TopNavBar />}>
              <Route index element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="*" element={<NoMatch />} />
            </Route>
          </Routes>
        </AuthIsSignedIn>
        <AuthIsNotSignedIn>
          <Routes>
            <Route path="/" element={<TopNavBar />}>
              <Route index element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="*" element={<NoMatch />} />
            </Route>
          </Routes>
        </AuthIsNotSignedIn>
      </AuthProvider>
    </div>
  );
}
